import React, { useState, useEffect } from 'react';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import PlantTree from '../../Components/PlantTree';
import {
	// Button,
	Grid,
	Typography
} from '@material-ui/core';
import ReactPlayer from 'react-player/lazy';
import {
	EmailShareButton,
	EmailIcon,
	FacebookShareButton,
	FacebookIcon,
	LinkedinShareButton,
	LinkedinIcon,
	TwitterShareButton,
	TwitterIcon,
	WhatsappShareButton,
	WhatsappIcon,
	FacebookMessengerShareButton,
	FacebookMessengerIcon
} from 'react-share';
// import InstagramIcon from "@material-ui/icons/Instagram";
// import GetAppIcon from "@material-ui/icons/GetApp";
// import { getClipInfo } from "../../Services/ClipInfo";
import { getShareAwardGallery } from '../../Services/getShareAwardGallery';
import { withStyles } from '@material-ui/core/styles';
// import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
// import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
// import Box from "@material-ui/core/Box";
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import { isMobile } from 'react-device-detect';

// const useStyles = makeStyles((theme) => ({
// 	root: {
// 		flexGrow: 1
// 	}
// }));

const styles = (theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2)
	},

	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	}
});

const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant='h6'>{children}</Typography>
			{onClose ? (
				<IconButton
					aria-label='close'
					className={classes.closeButton}
					onClick={onClose}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2)
	}
}))(MuiDialogContent);

// const DialogActions = withStyles((theme) => ({
//   root: {
//     margin: 0,
//     padding: theme.spacing(1),
//   },
// }))(MuiDialogActions);

const AwardGallery = (props) => {
	const [open, setOpen] = React.useState(false);
	const [awardGalleryVideos, setAwardGalleryVideos] = useState([]);
	const [isLoading, setLoading] = useState(false);
	const [name, setName] = useState('');
	const [titleVariant, setTitleVariant] = useState('h4');

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		document.title = 'KudosClips';
		getClipData();
		if (isMobile) {
			setTitleVariant('h5');
		}
	}, []);

	const AwardGalleryVideosList = () => {
		if (awardGalleryVideos.length > 0) {
			return awardGalleryVideos.map((element, index) => {
				return (
					<Grid item key={index} xs={12} sm={4}>
						<Grid item>
							<ReactPlayer
								width='100%'
								height='100%'
								url={element.output_url}
								controls={true}
							/>
						</Grid>
						<Grid item>
							{/* <Typography
                style={{
                  color: '#42536B',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  marginTop:'10px'
                }}
                color="textPrimary"
              >
                {JSON.parse(element.studentinfo).first_name} {' '} {JSON.parse(element.studentinfo).last_name}
                </Typography> */}
							{/* </Grid>
            <Grid item> */}
							<Typography
								style={{
									color: '#42536B',
									fontSize: '16px',
									fontWeight: '500',
									marginTop: '0px'
								}}
								color='textPrimary'
							>
								{JSON.parse(element.recognition_info).award_title}
							</Typography>
						</Grid>
						<Grid item>
							<Typography
								style={{
									color: '#42536b',
									fontSize: '20px',
									fontWeight: '500',
									// display: "flex",
									marginTop: '5px',
									alignItems: 'center'
								}}
								color='textPrimary'
								variant='h4'
							>
								Share
								<EmailShareButton
									style={{ padding: '5px' }}
									url={`${process.env.REACT_APP_CLIP_URL}/clips/${element.id}`}
								>
									<EmailIcon size={28} round={true} />
								</EmailShareButton>
								<FacebookShareButton
									style={{ padding: '5px' }}
									url={`${process.env.REACT_APP_CLIP_URL}/clips/${element.id}`}
								>
									<FacebookIcon size={28} round={true} />
								</FacebookShareButton>
								<LinkedinShareButton
									style={{ padding: '5px' }}
									url={`${process.env.REACT_APP_CLIP_URL}/clips/${element.id}`}
								>
									<LinkedinIcon size={28} round={true} />
								</LinkedinShareButton>
								<TwitterShareButton
									style={{ padding: '5px' }}
									url={`${process.env.REACT_APP_CLIP_URL}/clips/${element.id}`}
								>
									<TwitterIcon size={28} round={true} />
								</TwitterShareButton>
								<WhatsappShareButton
									style={{ padding: '5px' }}
									url={`${process.env.REACT_APP_CLIP_URL}/clips/${element.id}`}
								>
									<WhatsappIcon size={28} round={true} />
								</WhatsappShareButton>
								<FacebookMessengerShareButton
									style={{ padding: '5px' }}
									url={`${process.env.REACT_APP_CLIP_URL}/clips/${element.id}`}
								>
									<FacebookMessengerIcon size={28} round={true} />
								</FacebookMessengerShareButton>
								<IconButton
									style={{ margin: '0px 0px 15px 5px', padding: '0px' }}
									onClick={handleClickOpen}
								>
									<img
										src='../../instagram.svg'
										alt='instagram'
										style={{ height: '30px', borderRadius: '20px' }}
									/>
								</IconButton>
							</Typography>
						</Grid>
					</Grid>
				);
			});
		} else {
			return (
				<Typography
					style={{
						color: '#42536B',
						fontWeight: '700',
						// color: rgb(66, 83, 107);
						background: '#F1F1F1',
						padding: '14px 5px',
						borderRadius: '5px',
						border: '1px #ECEAEA solid',
						textAlign: 'center',
						marginLeft: '20px',
						width: '100%',
						marginTop: '20px'
					}}
					variant='h5'
				>
					No result found
				</Typography>
			);
		}
	};

	const getClipData = async () => {
		setLoading(true);
		let data = await getShareAwardGallery(localStorage.student_id);
		console.log('data ' + JSON.stringify(data));
		if (data.data.recognition_clip_details.length > 0) {
			let videosList = data.data.recognition_clip_details.filter(
				(a) => a.output_url !== null || a.output_url !== ''
			);
			setAwardGalleryVideos(videosList);
			var fname = JSON.parse(
				data.data.recognition_clip_details[0].studentinfo
			).first_name;
			var lname = JSON.parse(
				data.data.recognition_clip_details[0].studentinfo
			).last_name;
			// console.log(fname + lname)
			setName(`- ${fname} ${lname}`);
		}
		setLoading(false);
	};

	// const classes = useStyles();

	return (
		<div
			style={{
				backgroundColor: '#f8f8f8',
				minHeight: '100vh',
				display: 'flex',
				flexDirection: 'column'
			}}
		>
			<Header />
			<div>
				<div>
					{isLoading ? (
						<Grid container justify='center' style={{ marginBottom: '20%' }}>
							<CircularProgress />
						</Grid>
					) : (
						<Grid container style={{ paddingTop: '20px' }} justify='center'>
							<Grid container xs={8} spacing={2}>
								<Typography
									style={{
										color: '#42536B',
										paddingBottom: '10px',
										fontWeight: '700',
										padding: '20px 0px 40px 0px',
										marginBottom: '20px'
									}}
									color='textPrimary'
									variant={titleVariant}
								>
									{'Award Gallery ' + name}
								</Typography>
							</Grid>
							<Grid container xs={8} spacing={2}>
								<AwardGalleryVideosList />
							</Grid>
							<Grid item sm={12} style={{ paddingTop: '50px' }}>
								<PlantTree />
							</Grid>
						</Grid>
					)}
					<Grid
						container
						style={{
							display: 'flex',
							flexDirection: 'center',
							bottom: 0,

							width: '100%'
						}}
					>
						<Footer />
					</Grid>
				</div>

				<Dialog
					onClose={handleClose}
					aria-labelledby='customized-dialog-title'
					open={open}
				>
					<DialogTitle id='customized-dialog-title' onClose={handleClose}>
						Upload to Instagram
					</DialogTitle>
					<DialogContent dividers>
						<Typography gutterBottom>iOS - Safari</Typography>
						<ol>
							<li>
								<Typography gutterBottom>
									Press and hold the ‘Download StageClip to Device’ button
								</Typography>
							</li>
							<li>
								<Typography>Click ‘Download Linked File’ on the pop-up</Typography>
							</li>
							<li>
								<Typography>
									Click on the downloaded file and hit to save the video to your camera
									roll
								</Typography>
							</li>
						</ol>
						<Typography gutterBottom>Android</Typography>
						<ol>
							<li>
								<Typography gutterBottom>
									Press and hold the ‘Download StageClip to Device’ button
								</Typography>
							</li>
							<li>
								<Typography gutterBottom>
									Click ‘Download Link’ on the next menu
								</Typography>
							</li>
						</ol>
					</DialogContent>
				</Dialog>
			</div>
		</div>
	);
};

AwardGallery.propTypes = {};

export { AwardGallery };
