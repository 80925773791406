import './App.css';
// import { Home } from './Pages/Home';
import { useRoutes } from 'react-router-dom';
import routes from './Routes/routes';

function App() {
	const routing = useRoutes(routes);
	return (
		<div className='App'>
			{/* <Home/> */}
			{routing}
		</div>
	);
}

export default App;
