import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1
	},
	menuButton: {
		marginRight: theme.spacing(2)
	},
	title: {
		flexGrow: 1
	}
}));

export default function Footer() {
	const classes = useStyles();

	return (
		<div className={classes.root} style={{ paddingTop: '20px' }}>
			<Box
				style={{
					backgroundColor: '#212121',
					color: 'white'
				}}
			>
				<Container>
					<Toolbar>
						{/* <TableRow>
                <TableCell>privacy policies</TableCell>
                <TableCell>Terms and Conditions</TableCell>
                <TableCell>Create statements</TableCell>
                <TableCell>Contact us</TableCell>
              </TableRow> */}
						<Grid container spacing={1} sm={12}>
							<Grid item sm={3}>
								<a
									style={{ color: 'white', textDecoration: 'none' }}
									href='https://stageclip.freshdesk.com/support/tickets/new'
								>
									Contact Us
								</a>
							</Grid>
						</Grid>
						<Grid container spacing={6}>
							<Grid item lg={12}>
								<Typography
									style={{ textAlign: 'right' }}
									variant='body1'
									color='inherit'
								>
									&copy; 2021 KudosClip
								</Typography>
							</Grid>
						</Grid>
					</Toolbar>
				</Container>
			</Box>
		</div>
	);
}
