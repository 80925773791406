import React from 'react';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box } from '@material-ui/core';

// const text = [
// 	{
// 		primary: 'Brunch this week?',
// 		secondary: "I'll be in the neighbourhood this week. Let's grab a bite to eat"
// 	}
// ];
const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		paddingTop: '10px'
	},
	menuButton: {
		marginRight: theme.spacing(2)
	}
}));

export default function PlantTree() {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<Grid container>
				<Box style={{ backgroundColor: '#16272E', color: 'white', width: '100%' }}>
					<Grid container justify='center'>
						<Grid item style={{ textAlign: 'right' }} sm={2}>
							<IconButton>
								<img
									style={{
										width: '110px',
										height: '110px',
										marginTop: '10px',

										objectFit: 'contain'
									}}
									alt='plant tree'
									src='../../plantTree.png'
								/>
							</IconButton>
						</Grid>

						<Grid item xs={11} sm={10}>
							<Typography variant=''>
								<h3 style={{ marginLeft: '5px' }}>Share this Clip</h3>
								<p
									style={{
										marginLeft: '5px',
										textAlign: 'justify',
										marginRight: '10px'
									}}
								>
									When you share, you show you care! We're on a mission to plant a
									million trees and support global reforestation efforts.we'd love you to
									be part of our ambitious plan.
									<br></br>{' '}
									<a
										style={{ color: 'green' }}
										href='https://onetreeplanted.org/collections/where-we-plant'
										target='_blank'
										rel='noreferrer'
									>
										See more...
									</a>
								</p>
							</Typography>
							{/* <Typography variant="h6" color="inherit"
              
              >
                Share this Clip. Plant a tree
              </Typography> */}
						</Grid>
						{/* <Grid xs={1} sm={1}></Grid> */}
					</Grid>
				</Box>
			</Grid>
		</div>
	);
}
