import axiosInstance from "./AxioInstance";

export const getShareClipInfo = async (clipId) => {
  try {
    const res = await axiosInstance.get(
      `/student/${clipId}/recognition/share`,
      {
        data: null,
      }
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};
