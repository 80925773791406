import React from 'react';
import { Navigate } from 'react-router-dom';
import NotFoundView from '../Pages/errors/NotFoundView'
import { Home } from '../Pages/Home'
import {AwardGallery} from '../Pages/AwardGallery/AwardGallery'

const routes = [
  {
    path: '/clips/:id',
    element: <Home />
  },
  {
    path: '/clips/award-gallery',
    element: <AwardGallery />
  },
  {
    path: '/',
    element: <NotFoundView />,
    children: [
      { path: '404', element: <NotFoundView /> },
      { path: '*', element: <Navigate to="/404" /> },
    ]
  }
];

export default routes;
