import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1
	},

	menuButton: {
		marginRight: theme.spacing(2)
	},
	title: {
		display: 'none',
		[theme.breakpoints.up('sm')]: {
			display: 'block'
		}
	}
}));

export default function Header() {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<AppBar style={{ backgroundColor: 'white', position: 'static' }}>
				<Toolbar>
					<div style={{ padding: '10px' }}>
						<a href='https://stageclip.com/' rel='noreferrer' target='_blank'>
							<img
								src='../../logo.svg'
								alt='KudosClip'
								style={{ height: '60px' }}
							></img>
						</a>
					</div>
					<Typography variant='h6' className={classes.title}>
						KudosClip
					</Typography>
				</Toolbar>
			</AppBar>
		</div>
	);
}
