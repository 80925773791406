import React, {
	useState,
	useEffect
	// useRef
} from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import PlantTree from '../Components/PlantTree';
import {
	// Button,
	Grid,
	Typography
} from '@material-ui/core';
// import ReactPlayer from 'react-player/lazy';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import {
	Player,
	BigPlayButton,
	ControlBar
	// PosterImage
} from 'video-react';
import './Home.css';
import {
	EmailShareButton,
	EmailIcon,
	FacebookShareButton,
	FacebookIcon,
	LinkedinShareButton,
	LinkedinIcon,
	TwitterShareButton,
	TwitterIcon,
	WhatsappShareButton,
	WhatsappIcon,
	FacebookMessengerShareButton,
	FacebookMessengerIcon
} from 'react-share';
// import GetAppIcon from '@material-ui/icons/GetApp';
import { getShareClipInfo } from '../Services/shareClipInfo';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
// import screenful from 'screenfull';
// import Controls from '../Components/Controls';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1
	},
	large: {
		width: theme.spacing(7),
		height: theme.spacing(7)
	},
	playerWrapper: {
		width: '100%',
		position: 'relative'
	},

	controlsWrapper: {
		visibility: 'hidden',
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		background: 'rgba(0,0,0,0.4)',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between'
	},
	topControls: {
		display: 'flex',
		justifyContent: 'flex-end',
		padding: theme.spacing(2)
	},
	middleControls: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	bottomWrapper: {
		display: 'flex',
		flexDirection: 'column',
		padding: theme.spacing(2)
	},

	bottomControls: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},

	button: {
		margin: theme.spacing(1)
	},
	controlIcons: {
		color: '#777',

		fontSize: 50,
		transform: 'scale(0.9)',
		'&:hover': {
			color: '#fff',
			transform: 'scale(1)'
		}
	},

	volumeSlider: {
		width: 50
	}
}));

const styles = (theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2)
	},

	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	}
});

const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant='h6'>{children}</Typography>
			{onClose ? (
				<IconButton
					aria-label='close'
					className={classes.closeButton}
					onClick={onClose}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2)
	}
}))(MuiDialogContent);

// const format = (seconds) => {
// 	if (isNaN(seconds)) {
// 		return `00:00`;
// 	}
// 	const date = new Date(seconds * 1000);
// 	const hh = date.getUTCHours();
// 	const mm = date.getUTCMinutes();
// 	const ss = date.getUTCSeconds().toString().padStart(2, '0');
// 	if (hh) {
// 		return `${hh}:${mm.toString().padStart(2, '0')}:${ss}`;
// 	}
// 	return `${mm}:${ss}`;
// };

// let count = 0;

const Home = (props) => {
	const [open, setOpen] = React.useState(false);
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');

	const playerRef = React.useRef(null);
	// const [className, setClassName] = useState('');
	const [videoUrl, setVideo] = useState('');
	const [groupImage, setGroupImage] = useState('');
	const [logo, setLogo] = useState('');
	const [isLoading, setLoading] = useState(false);
	// const [timeDisplayFormat, setTimeDisplayFormat] = React.useState('normal');
	const [awardTitle, setAwardTitle] = useState('');
	const [teacherName, setTeacherName] = useState('');
	const [schoolName, setSchoolName] = useState('');
	// const [state, setState] = useState({
	// 	pip: false,
	// 	playing: true,
	// 	controls: false,
	// 	light: false,
	// 	muted: true,
	// 	played: 0,
	// 	duration: 0,
	// 	playbackRate: 1.0,
	// 	volume: 1,
	// 	loop: false,
	// 	seeking: false
	// });

	// const playerRef = useRef(null);
	// const playerContainerRef = useRef(null);
	// const controlsRef = useRef(null);
	// const {
	// 	playing,
	// 	controls,
	// 	light,

	// 	muted,
	// 	loop,
	// 	playbackRate,
	// 	pip,
	// 	played,
	// 	seeking,
	// 	volume
	// } = state;

	// const handlePlayPause = () => {
	// 	setState({ ...state, playing: !state.playing });
	// };

	// const handleRewind = () => {
	// 	playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10);
	// };

	// const handleFastForward = () => {
	// 	playerRef.current.seekTo(playerRef.current.getCurrentTime() + 10);
	// };

	// const handleProgress = (changeState) => {
	// 	if (count > 3) {
	// 		controlsRef.current.style.visibility = 'hidden';
	// 		count = 0;
	// 	}
	// 	if (controlsRef.current.style.visibility == 'visible') {
	// 		count += 1;
	// 	}
	// 	if (!state.seeking) {
	// 		setState({ ...state, ...changeState });
	// 	}
	// };

	// const handleSeekChange = (e, newValue) => {
	// 	console.log({ newValue });
	// 	setState({ ...state, played: parseFloat(newValue / 100) });
	// };

	// const handleSeekMouseDown = (e) => {
	// 	setState({ ...state, seeking: true });
	// };

	// const handleSeekMouseUp = (e, newValue) => {
	// 	console.log({ value: e.target });
	// 	setState({ ...state, seeking: false });
	// 	// console.log(sliderRef.current.value)
	// 	playerRef.current.seekTo(newValue / 100, 'fraction');
	// };

	// const handleDuration = (duration) => {
	// 	setState({ ...state, duration });
	// };

	// const handleVolumeSeekDown = (e, newValue) => {
	// 	setState({ ...state, seeking: false, volume: parseFloat(newValue / 100) });
	// };
	// const handleVolumeChange = (e, newValue) => {
	// 	// console.log(newValue);
	// 	setState({
	// 		...state,
	// 		volume: parseFloat(newValue / 100),
	// 		muted: newValue === 0 ? true : false
	// 	});
	// };

	// const toggleFullScreen = () => {
	// 	screenful.toggle(playerContainerRef.current);
	// };

	// const handleMouseMove = () => {
	// 	console.log('mousemove');
	// 	controlsRef.current.style.visibility = 'visible';
	// 	count = 0;
	// };

	// const hanldeMouseLeave = () => {
	// 	controlsRef.current.style.visibility = 'hidden';
	// 	count = 0;
	// };

	// const handleDisplayFormat = () => {
	// 	setTimeDisplayFormat(timeDisplayFormat === 'normal' ? 'remaining' : 'normal');
	// };

	// const handlePlaybackRate = (rate) => {
	// 	setState({ ...state, playbackRate: rate });
	// };

	// const hanldeMute = () => {
	// 	setState({ ...state, muted: !state.muted });
	// };

	// const currentTime =
	// 	playerRef && playerRef.current ? playerRef.current.getCurrentTime() : '00:00';

	// const duration =
	// 	playerRef && playerRef.current ? playerRef.current.getDuration() : '00:00';
	// const elapsedTime =
	// 	timeDisplayFormat === 'normal'
	// 		? format(currentTime)
	// 		: `-${format(duration - currentTime)}`;

	// const totalDuration = format(duration);

	// const handleClickOpen = () => {
	// 	setOpen(true);
	// };

	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		document.title = 'KudosClips';
		getClipData();
	}, []);

	const getClipData = async () => {
		setLoading(true);
		var params = window.location.href.substring(
			window.location.href.lastIndexOf('/') + 1
		);
		var clipId = params.split('?')[0];
		console.log(clipId);
		let data = await getShareClipInfo(clipId);
		console.log(data);
		if (data.data) {
			if (data.data.student_pii.id) {
				console.log('student id if ' + data.data.student_pii.id);
				localStorage.setItem('student_id', data.data.student_pii.id);
			} else {
				console.log('student id else ' + data.data.student_id);
				localStorage.setItem('student_id', data.data.student_id);
			}
			// setClassName(
			// 	data.data.class_info.class_name
			// 		? data.data.class_info.class_name
			// 		: data.data.class_info.name
			// );

			setFirstName(
				data.data.student_pii.first_name
					? data.data.student_pii.first_name
					: data.data.student_pii.fname
			);
			setSchoolName(data.data.school_info.school_name);

			setAwardTitle(data.data.recognition_info.award_title);

			setTeacherName(
				data.data.recognition_info.teacher_name
					? 'Presented by ' + data.data.recognition_info.teacher_name
					: null
			);

			setLastName(
				data.data.student_pii.last_name
					? data.data.student_pii.last_name
					: data.data.student_pii.lname
			);
			console.log(data.data.student_pii.fname);
			setVideo(data.data.video);
			if (data.data.student_photo) {
				setGroupImage(data.data.student_photo);
			} else {
				setGroupImage(data.data.group_logo);
			}

			setLogo(
				data.data.group_logo ? data.data.group_logo : '../../school_logo.png'
			);
			document.title = `${
				data.data.student_pii.first_name
					? data.data.student_pii.first_name
					: data.data.student_pii.fname + ' ' + data.data.student_pii.last_name
					? data.data.student_pii.last_name
					: data.data.student_pii.lname
			} | KudosClips`;
		}
		setLoading(false);
		if (data.error) {
			console.log('error');
			window.location.href = `${window.location.origin}/`;
		}
	};

	const classes = useStyles();

	return (
		<div
			style={{
				backgroundColor: '#f8f8f8',
				minHeight: '100vh',
				display: 'flex',
				flexDirection: 'column'
			}}
		>
			<Header />
			<div>
				<div>
					{isLoading ? (
						<Grid container justify='center' style={{ marginBottom: '20%' }}>
							<CircularProgress />
						</Grid>
					) : (
						<div>
							<>
								<Grid container>
									<Grid container style={{ paddingTop: '15px' }}>
										<Grid container>
											<Grid item sm={3}></Grid>
											<Grid item sm={6} xs={12}>
												<Typography
													className='title'
													style={{
														fontSize: '26px',
														textAlign: 'left',
														fontWeight: 'bold'
													}}
												>
													{awardTitle}
												</Typography>

												<Typography
													className='title'
													style={{
														textAlign: 'left',
														fontSize: '16px'
													}}
												>
													{teacherName}
												</Typography>
												<div style={{ paddingTop: '15px' }}>
													<Player
														poster={groupImage}
														preload='auto'
														ref={playerRef}
														onEnded={() => playerRef.current.load()}
													>
														<BigPlayButton position='center' />
														<ControlBar autoHide={true} className='my-class' />
														<source src={videoUrl} />
													</Player>
												</div>
												{/* <div
                          onMouseMove={handleMouseMove}
                          onMouseLeave={hanldeMouseLeave}
                          ref={playerContainerRef}
                          className={classes.playerWrapper}
                          id="videoID"
                        >
                          <ReactPlayer
                            ref={playerRef}
                            width="100%"
                            height="100%"
                            url={videoUrl}
                            pip={pip}
                            playing={playing}
                            controls={false}
                            light={light}
                            loop={loop}
                            playbackRate={playbackRate}
                            volume={volume}
                            muted={muted}
                            onProgress={handleProgress}
                            config={{
                              file: {
                                attributes: {
                                  crossorigin: "anonymous",
                                },
                              },
                            }}
                          />

                          <Controls
                            ref={controlsRef}
                            onSeek={handleSeekChange}
                            onSeekMouseDown={handleSeekMouseDown}
                            onSeekMouseUp={handleSeekMouseUp}
                            onDuration={handleDuration}
                            onPlayPause={handlePlayPause}
                            playing={playing}
                            played={played}
                            elapsedTime={elapsedTime}
                            totalDuration={totalDuration}
                            onMute={hanldeMute}
                            muted={muted}
                            onVolumeChange={handleVolumeChange}
                            onVolumeSeekDown={handleVolumeSeekDown}
                            onChangeDispayFormat={handleDisplayFormat}
                            playbackRate={playbackRate}
                            onPlaybackRateChange={handlePlaybackRate}
                            onToggleFullScreen={toggleFullScreen}
                            volume={volume}
                          />
                        </div> */}
											</Grid>
											<Grid item sm={3}></Grid>
										</Grid>
										<Grid item xs={1} sm={3}></Grid>
										<Grid item xs={10} sm={6} style={{ paddingTop: '10px' }}>
											<Grid container>
												<Grid className='Avatar' item sm={6} xs={12}>
													<List>
														<ListItem>
															<ListItemAvatar>
																<Avatar
																	style={{}}
																	alt='Remy Sharp'
																	src={logo}
																	className={classes.large}
																/>
															</ListItemAvatar>
															<ListItemText
																style={{
																	marginLeft: '10px',
																	marginTop: '0px'
																	// marginTop: '-5px'
																}}
																secondary={
																	<Typography
																		color='textSecondary'
																		variant='caption'
																		style={{ fontSize: '12px' }}
																	>
																		{schoolName}
																	</Typography>
																}
															>
																<Typography
																	className='xxxx'
																	style={{
																		fontSize: '20px',
																		fontWeight: '500'
																	}}
																	color='textPrimary'
																	variant='h4'
																>
																	{firstName} {lastName}
																</Typography>
															</ListItemText>
														</ListItem>
													</List>
												</Grid>
												<Grid
													className='Social-media'
													item
													sm={6}
													xs={12}
													style={{
														display: 'flex',
														justifyContent: 'flex-end',
														alignItems: 'center'
													}}
												>
													<EmailShareButton
														style={{ padding: '5px', outline: 'none' }}
														url={window.location.href}
													>
														<EmailIcon size={28} round={true} />
													</EmailShareButton>
													<FacebookShareButton
														style={{ padding: '5px', outline: 'none' }}
														url={window.location.href}
													>
														<FacebookIcon size={28} round={true} />
													</FacebookShareButton>
													<LinkedinShareButton
														style={{ padding: '5px', outline: 'none' }}
														url={window.location.href}
													>
														<LinkedinIcon size={28} round={true} />
													</LinkedinShareButton>
													<TwitterShareButton
														style={{ padding: '5px', outline: 'none' }}
														url={window.location.href}
													>
														<TwitterIcon size={28} round={true} />
													</TwitterShareButton>
													<WhatsappShareButton
														style={{ padding: '5px', outline: 'none' }}
														url={window.location.href}
													>
														<WhatsappIcon size={28} round={true} />
													</WhatsappShareButton>
													<FacebookMessengerShareButton
														style={{ padding: '5px', outline: 'none' }}
														url={window.location.href}
													>
														<FacebookMessengerIcon size={28} round={true} />
													</FacebookMessengerShareButton>
													{/* <IconButton
														style={{
															margin: '0px 0px 5px 5px',
															padding: '0px'
														}}
														onClick={handleClickOpen}
													>
														<img
															alt='Instagram'
															src='../../instagram.svg'
															style={{ height: '30px', borderRadius: '20px' }}
														/>
													</IconButton> */}
												</Grid>
												{/* <Grid
                            item
                            style={{ paddingTop: "10px", textAlign: "right" }}
                            sm={3}
                            xs={12}
                          ></Grid>
                          <Grid
                            className="Down"
                            item
                            style={{ textAlign: "right" }}
                            sm={3}
                            xs={12}
                          >
                            <Button
                              color="primary"
                              variant="contained"
                              className="Download-button"
                              href={videoUrl}
                              style={{
                                color: "#ffffff",
                                backgroundColor: "#e77728",
                                textTransform: "capitalize",
                                borderRadius: "8px",
                                outline: "none",
                              }}
                            >
                              Download
                              <GetAppIcon
                                style={{
                                  margin: "0px 0px 0px 5px",
                                }}
                              />
                            </Button>
                          </Grid> */}
											</Grid>
										</Grid>
										<Grid item xs={1} sm={3}></Grid>

										<Grid container>
											<Grid item sm={3}></Grid>
											<Grid item xs={12} sm={6}>
												<PlantTree />
											</Grid>
											<Grid item sm={3}></Grid>
										</Grid>
									</Grid>
								</Grid>
							</>
						</div>
					)}

					<Grid
						container
						style={{
							display: 'flex',
							flexDirection: 'center',
							bottom: 0,

							width: '100%'
						}}
					>
						<Footer />
					</Grid>
				</div>

				<Dialog
					onClose={handleClose}
					aria-labelledby='customized-dialog-title'
					open={open}
				>
					<DialogTitle id='customized-dialog-title' onClose={handleClose}>
						Upload to Instagram
					</DialogTitle>
					<DialogContent dividers>
						<Typography gutterBottom>iOS - Safari</Typography>
						<ol>
							<li>
								<Typography gutterBottom>
									Press and hold the ‘Download StageClip to Device’ button
								</Typography>
							</li>
							<li>
								<Typography>Click ‘Download Linked File’ on the pop-up</Typography>
							</li>
							<li>
								<Typography>
									Click on the downloaded file and hit to save the video to your camera
									roll
								</Typography>
							</li>
						</ol>
						<Typography gutterBottom>Android</Typography>
						<ol>
							<li>
								<Typography gutterBottom>
									Press and hold the ‘Download StageClip to Device’ button
								</Typography>
							</li>
							<li>
								<Typography gutterBottom>
									Click ‘Download Link’ on the next menu
								</Typography>
							</li>
						</ol>
					</DialogContent>
				</Dialog>
			</div>
		</div>
	);
};

Home.propTypes = {};

export { Home };
