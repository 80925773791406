import axiosInstance from "./AxioInstance";

export const getShareAwardGallery = async (studentId) => {
  try {
    const res = await axiosInstance.get(
      `/student/${studentId}/allClips`,
      {
        data: null,
      }
    );
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};
